import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca health`}</strong>{` -- get the status of the CA`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca health
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca health`}</strong>{` makes an API request to the /health
endpoint of the Step CA to check if it is running. If the CA is healthy, the
response will be 'ok'.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Using the required flags:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca health --ca-url https://ca.smallstep.com:8080 --root path/to/root_ca.crt
ok
`}</code></pre>
    <p>{`With the required flags preconfigured:`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{` is set using environment variables (as STEP_CA_URL) or the default
configuration file in `}<inlineCode parentName="p">{`$STEPPATH/config/defaults.json`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{` is set using environment variables (as STEP_ROOT), the default
configuration file in `}<inlineCode parentName="p">{`$STEPPATH/config/defaults.json`}</inlineCode>{` or the default root
certificate located in `}<inlineCode parentName="p">{`$STEPPATH/certs/root_ca.crt`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca health
ok
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      